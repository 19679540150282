<template>
  <div class="selectMallOrderDetail"> 
    <div class="title">
      <div class="left"></div>
      <div class="text">基本信息</div>
    </div>
    <el-form label-width="100px">
      <div class="form_box">
        <el-form-item label="订单ID:">
          {{orderdetail.order_id}}
        </el-form-item>
        <el-form-item label="订单编号:">
          {{orderdetail.order_coding}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="订单积分:">
          {{orderdetail.order_integral}}
        </el-form-item>
        <el-form-item label="优惠券金额:">
          {{orderdetail.order_coupon_price}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="实付金额:">
          {{orderdetail.order_balance}}
        </el-form-item>
        <el-form-item label="总金额:">
          {{orderdetail.order_total_price}}
        </el-form-item>
      </div>
      <div class="form_box">
        <el-form-item label="下单时间:">
          {{orderdetail.order_create_time}}
        </el-form-item> 
       
        <el-form-item label="订单状态:" style="width:550px">
          <span style="margin-right:10px;">
            {{
            orderdetail.order_status==1?'待付款' :
            orderdetail.order_status==2?'待发货' :
            orderdetail.order_status==3?'待收货' :
            orderdetail.order_status==3&&orderdetail.order_receive_way==0?'待收货' :
            orderdetail.order_status==3&&orderdetail.order_receive_way==1?'待提货' :
            orderdetail.order_status==4?'待评价' :
            orderdetail.order_status==5?'申请退款' :
            orderdetail.order_status==6?'同意退款待用户退货' :
            orderdetail.order_status==7?'用户已退货待商家确认' :
            orderdetail.order_status==8?'退货成功' :
            orderdetail.order_status==9?'已完成' :
            orderdetail.order_status==10?'交易取消' :'未知状态'
           }}
          </span> 
        
            <el-button type="primary" v-if="orderdetail.order_status==3 || orderdetail.order_status==2 && orderdetail.order_receive_way == 1" @click="enterHuo()">确认自提</el-button>
                    <el-button type="primary" v-if="orderdetail.order_status==3  && orderdetail.order_receive_way !== 1 " @click="toShowSend()">更改物流</el-button>

          <el-button type="primary" v-if="orderdetail.order_status==2" @click="toShowSend()">发货</el-button>
          <el-button type="primary" v-if="(orderdetail.order_status==3 || orderdetail.order_status==2) && orderdetail.order_receive_way !== 1 " @click="enterHuo()" >确认收货</el-button>
         
         

        </el-form-item>
        
      </div>
      <div class="form_box">
        <el-form-item label="活动标题:">
          {{orderdetail.activityTitle}}
        </el-form-item>
        <el-form-item label="区域名称:">
          {{orderdetail.areaName}}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="订单来源:">
          {{orderdetail.strSource}}
        </el-form-item> 
        <el-form-item label="用户备注:" v-if="orderdetail.order_remark">
          {{orderdetail.order_remark}}
        </el-form-item> 
        <el-form-item >
                <el-button size="mini" type="primary" @click="setBeiZhu()">管理员备注</el-button>

        </el-form-item> 
      </div>


      
    </el-form> 
    <div>
      <div class="title">
        <div class="left"></div>
        <div class="right">取货方式</div>
      </div> 
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="取货方式:">
            {{orderdetail.order_receive_way==0?'快递' :'自提'}}
          </el-form-item>
        </div> 
        <div class="" v-if="orderdetail.order_receive_way==0">
          <div class="form_box">
            <el-form-item label="取货人:">
              {{orderdetail.userAddr.addr_user_name}}
            </el-form-item>
            <el-form-item label="手机号码:">
              {{orderdetail.userAddr.addr_user_phone}}
            </el-form-item>
          </div>
          <el-form-item label="取货地址:">
            {{orderdetail.userAddr.addr_province_name}}{{orderdetail.userAddr.addr_city_name}}{{orderdetail.userAddr.addr_area_name}}{{orderdetail.userAddr.addr_detail}}
          </el-form-item>
        </div> 
        <div v-if="orderdetail.order_receive_way==1">
          <div class="form_box">
            <el-form-item label="取货人:">
              {{orderdetail.order_receive_name}}
            </el-form-item>
            <el-form-item label="手机号:"> 
              {{orderdetail.order_receive_phone}}
            </el-form-item>
          </div>
          <el-form-item label="自提地点:">
            {{orderdetail.storeExtractAddr ? orderdetail.storeExtractAddr.extract_addr : ''}}
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div v-if="orderdetail.userInfo">
      <div class="title">
        <div class="left"></div>
        <div class="text">下单人信息</div>
      </div>
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="用户ID:">
            {{orderdetail.userInfo.user_id}}
          </el-form-item>
          <el-form-item label="用户头像:">
            <el-image style="width:50px; height:50px" :src="orderdetail.userInfo.user_headImgUrl" :preview-src-list="[orderdetail.userInfo.user_headImgUrl]">
            </el-image>
          </el-form-item>
          <el-form-item label="昵称:">
            {{orderdetail.userInfo.user_nickName}}
          </el-form-item>
        </div>
      </el-form>
    </div>
       <div v-if="orderdetail.order_master_remark">
        <div class="title">
          <div class="left"></div>
          <div class="text">操作日志</div>
        </div>
        <div v-for="(key,ix) in orderdetail.order_master_remark.split('--FYW--')" :key="ix">
          {{key}}
        </div>
       </div>
    <div v-if="orderdetail.storeInfo">
      <div class="title">
        <div class="left"></div>
        <div class="text">店铺信息</div>
      </div>
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="店铺ID:">
            {{orderdetail.storeInfo.store_id}}
          </el-form-item>
          <el-form-item label="店铺封面:">
            <el-image style="width:50px; height:50px" :src="orderdetail.storeInfo.store_home_img" :preview-src-list="[orderdetail.storeInfo.store_home_img]">
            </el-image>
          </el-form-item>
        </div>
        <div class="form_box">
          <el-form-item label="店铺名称:">
            {{orderdetail.storeInfo.store_name}}
          </el-form-item>
          <el-form-item label="店铺电话:">
            {{orderdetail.storeInfo.store_tel}}
          </el-form-item>
        </div>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="orderdetail.order_express_name">
      <div class="title">
        <div class="left"></div>
        <div class="text">快递信息</div>
      </div>
      <el-form label-width="100px">
        <div class="form_box">
          <el-form-item label="快递名称:">
            {{orderdetail.order_express_name}}
          </el-form-item>
          <el-form-item label="快递单号:">
            {{orderdetail.order_express_coding}}
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="onShowExpress()">物流详情</el-button>
          </el-form-item>
        </div>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="orderdetail.storeUserOrderProdList">
      <div class="title">
        <div class="left"></div>
        <div class="text">订单商品</div>
      </div> 
      <el-table border size="mini" ref="multipleTable" :data="orderdetail.storeUserOrderProdList" tooltip-effect="dark" style="margin-top:40px; width:98%;">
        <el-table-column align="center" prop="prod_id" label="商品ID" width="width"></el-table-column>
        <el-table-column align="center" prop="prod_name" label="商品名称" width="width"></el-table-column>
        <el-table-column align="center" prop="prod_home_img" label="商品封面" width="width">
          <template slot-scope="{ row }">
            <el-image style="width:50px; height:50px" :src="row.prod_home_img" :preview-src-list="[row.prod_home_img]" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="prod_buy_price" label="商品规格" width="width">
          <template slot-scope="{ row }">
            {{row.prod_one_item_name||''}} {{row.prod_two_item_name||''}} {{row.prod_three_item_name||''}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="prod_buy_price" label="商品价格" width="width">
          <template slot-scope="{ row }">
            {{row.prod_buy_price}} x {{row.prod_buy_count}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="prod_buy_integral" label="商品积分" width="width">
          <template slot-scope="{ row }">
            {{row.prod_buy_integral}} x {{row.prod_buy_count}}
          </template>
        </el-table-column> 
        <el-table-column align="center" prop="order_profit_way" label="佣金结算方式" width="width">
          <template slot-scope="{ row }" v-if="row.order_profit_way">
            {{row.order_profit_way==1?'购买佣金立返':row.order_profit_way==2?'出游佣金立返':''}}
          </template>
        </el-table-column> 
      </el-table>
       <el-button style="marfin-top:20px" type="primary" @click="$router.go(-1)">返 回</el-button>
    </div> 
  
  
    <el-dialog :close-on-click-modal="false" title="发货" :visible.sync="sendVisible" width="width">
      <el-form :rules="sendRules" 
      :model="sendForm" 
      ref="sendRuleForm" 
        v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
      label-width="80px">
        <el-form-item label="选择物流" prop="expressId">
          <el-select style="width:300px;" v-model.trim="sendForm.expressId" @change="changeExpress">
            <el-option v-for="(i, index) in express" :key="i.expressId" :value="i.expressId" :label="i.expressName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="expressCoding">
          <el-input v-model="sendForm.expressCoding" placeholder="请输入物流编号" style="width:300px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="sendVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmToSend()">确 定</el-button>
      </div>
    </el-dialog>
        <!-- 物流信息 -->
    <el-dialog
    v-show="isShowExpress"
    title="提示"
    :visible.sync="dialogVisible"
    width="40%"
    >
         <template>
        <li class="checkYep">{{expressCheck}}</li>
      </template>
    <div v-for="item in expressDetail" :key="item.action">
   
    <ul>
      <li class="exp">{{item.AcceptStation}}</li>
    </ul>
    </div>
   
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
<el-dialog
   
    title="备注"
    :visible.sync="isShowMarks"
    width="40%"
    >
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="mark">
        </el-input>
   
  <span slot="footer" class="dialog-footer">
    <el-button @click="isShowMarks = false">取 消</el-button>
    <el-button type="primary" @click="maskSubmit">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import {
  expressAll,
  orderDetailApi,
  orderToSend,
  getExpressDetail,
  enterSh,
  remarks,
} from "../../../api/selectMall.js";
export default {
  name: "selectMallOrderDetail",
  data() {
    return {
      expressCheck: "",
      isShowMarks: false,
      expressDetail: "",
      dialogVisible: false,
      isShowExpress: false,
      express: [],
      mark:'',
      sendVisible: false,
      sendForm: {
        expressId: "",
        expressName: "",
        expressCoding: "",
      },
      sendRules: {
        expressId: [
          {
            required: true,
            message: "请选择发货物流",
            trigger: "change",
          },
        ],
        expressCoding: [
          {
            required: true,
            message: "请输入物流编号",
            trigger: "blur",
          },
        ],
      },

      orderdetail: {},
    };
  },
  created() {
    console.log(this.$route.query);
    console.log("路由", this.$route);
    this.getDetail();
    this.getExpress();
  },
  methods: {
   async  maskSubmit() {
      if(!this.mark){
        return this.$message.warning("请输入备注")
      }
       const params = {
          orderId: this.orderdetail.order_id,
          remark: this.mark
        }
        const {data} = await remarks(params)
        if(data.code == 0){

          this.$message.success(data.msg)
         this.isShowMarks = false
        this.getDetail() // 获取表格数据
        }else{
           this.$message.warning(data.msg)
        }
    },
    setBeiZhu() {
      this.isShowMarks = true
     
    },
    enterHuo() {
      let str = this.orderdetail.order_receive_way == 1 ? "自提" : "收货";
      this.$confirm(`是否确认${str}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await enterSh({
            id: this.$route.query.id,
          });
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.getDetail();
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {});
    },
    async onShowExpress() {
      this.dialogVisible = true;
      this.isShowExpress = true;
      const { data } = await getExpressDetail({
        orderId: this.$route.query.id,
      });
      this.expressCheck =
        data.data.expResult.Traces[data.data.expResult.Traces.length - 1];
      this.expressCheck = this.expressCheck.AcceptStation;
      this.expressDetail = data.data.expResult.Traces;
      this.expressDetail.reverse().shift();
    },
    async getDetail() {
      const { data } = await orderDetailApi({
        orderId: this.$route.query.id,
      });
      if (data.code == 0) {
        this.orderdetail = data.data;
      }
    },
    async getExpress() {
      const { data } = await expressAll({
        orderId: this.$route.query.id,
      });
      if (data.code == 0) {
        this.express = data.list;
      }
    },
    toShowSend() {
      this.sendVisible = true;
    },
    changeExpress(val) {
      console.log(val);
      this.express.forEach((item) => {
        if (item.expressId == val) {
          this.sendForm.expressName = item.expressName;
        }
      });
    },
    confirmToSend() {
      this.$refs.sendRuleForm.validate(async (valid) => {
        if (valid) {
          const { data } = await orderToSend({
            orderId: this.orderdetail.order_id,
            expressId: this.sendForm.expressId,
            expressName: this.sendForm.expressName,
            expressCoding: this.sendForm.expressCoding,
          });
          if (data.code == 0) {
            this.$message.success("发货成功!");
            this.sendVisible = false;
            this.getDetail();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          this.$message({
            type: "error",
            message: "请把数据填写完整",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.selectMallOrderDetail {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .return {
    margin-top: 50px;
  }

  .el-table {
    margin-top: 30px;
  }

  .title {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .left {
      width: 5px;
      height: 13px;
      background: #6a8abe;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .form_box {
    display: flex;

    & > div {
      width: 300px;
    }
  }
}

.exp {
  font-size: 18px;
  // font-weight:bold ;
  line-height: 50px;
}
.checkYep {
  line-height: 50px;
  font-size: 18px;
  color: #ffa544;
  line-height: px;
}
</style>
